<template>
    <div>
        <header id="page-topbar">
            <div class="navbar-header">
                <div class="d-flex">
                    <!-- LOGO -->
                    <h1 class="navbar-brand-box">
                        <div class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="@/assets/images/logo-header.png" alt height="36" />
                            </span>
                            <span class="logo-lg">
                                <img src="@/assets/images/logo-header.png" alt height="30" />
                            </span>
                        </div>

                        <div class="logo logo-light">
                            <span class="logo-sm">
                                <img src="@/assets/images/logo-header-light.png" alt height="36" />
                            </span>
                            <span class="logo-lg">
                                <img src="@/assets/images/logo-header-light.png" alt height="30" />
                            </span>
                        </div>
                    </h1>
                </div>
            </div>
        </header>
    </div>
    <div id="layout-wrapper">
        <div class="main-content">
            <div class="page-content mt-5 mb-4">
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-body">
                            <div class="" v-if="successPayment == true">
                                <div class="card payment_form">
                                    <div class="card-body">
                                        <div class="paymentSuccess text-center">
                                            <img src="@/assets/images/submitit/check-3.gif" width="200" display="block"
                                                margin="auto">
                                            <h2 class="text-body-9">Success!</h2>

                                            <!-- <p style="color: #454545; font-weight: 500; font-size: 16px;">Thank you for signing up
                                    with Submitit!</p> -->
                                            <!-- <p v-if="showMessage" class="fade-in">
                                    Logging You In, please wait...
                                </p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-lg-8">
                                    <div class="card border shadow-none">
                                        <div class="card-body">
                                            <h4 class="card-title mb-4">Selected Packages</h4>
                                            <div class="table-responsive">
                                                <table class="table mb-0 remove-bg table-responsive">
                                                    <thead class="table-light">
                                                        <tr>
                                                            <th>Package/Services <sup class="text-theme" v-if="this.storyDetails">({{ this.storyDetails.storyTitle }})</sup></th>
                                                            <!-- {/* <th>Services</th> */} -->
                                                            <th>Words</th>
                                                            <th class="text-end">Price</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody v-if="isServicePayment">
                                                        <tr v-for="(paymentData, index) in paymentLinkPackages"
                                                            :key="index">
                                                            <!-- {/* <td rowspan="1" class="fw-medium">
                                                                {{ paymentData.type }} Package
                                                            </td> */} -->
                                                            <td v-if="paymentData.type == 'editing'">
                                                                <p class="mb-0">{{ paymentData.title }} <sup
                                                                        class="text-theme">(${{
                                                                            paymentData.amount }}/1000 words)</sup></p>

                                                            </td>
                                                            <td v-else>
                                                                <p class="mb-0">{{ paymentData.title }} </p>

                                                            </td>
                                                            <td>{{ storyWordCount }}</td>
                                                            <td class="text-end" v-if="paymentData.type == 'editing'">
                                                                ${{
                                                                    parseFloat((Number(storyWordCount) / 1000) *
                                                                        Number(editingPackage.amount)).toFixed(2) }}</td>
                                                            <td class="text-end" v-else>${{
                                                                parseFloat(paymentData.amount).toFixed(2) }}</td>

                                                        </tr>
                                                        <tr v-if="storyWordCount > 3500 && !editingPackage">
                                                            <td>
                                                                <p class="mb-0 fw-medium">Reading Charge <sup
                                                                        class="text-theme">($35/1,000
                                                                        words
                                                                        (over
                                                                        3,500))</sup> </p>

                                                            </td>
                                                            <td>{{ parseInt(storyWordCount) - parseInt(3500) }}</td>

                                                            <td class="text-end">${{ parseFloat((Number(storyWordCount)
                                                                - 3500) / 1000 *
                                                                35).toFixed(2) }}</td>
                                                        </tr>
                                                        <tr
                                                            v-if="(submissionPackage?.title == '20 Journals' || fictionPackage?.title == '20 Journals (3 Stories)' || submissionPackage?.title == 'Journals List Only') && (editingPackage && editingPackage.title != 'Consulting: 1 hour')">
                                                            <td class="mb-0 fw-medium" colspan="2">Editing Discount: 10%
                                                            </td>
                                                            <td class="text-end" colspan="4"> - ${{
                                                                parseFloat((parseFloat(((Number(storyWordCount) /
                                                                    1000) *
                                                                    editingPackage.amount)) * 10) / 100).toFixed(2) }}</td>
                                                        </tr>
                                                        <tr
                                                            v-else-if="(submissionPackage?.title == '20 Journals' || fictionPackage?.title == '20 Journals (3 Stories)' || submissionPackage?.title == 'Journals List Only') && (editingPackage && editingPackage.title == 'Consulting: 1 hour')">
                                                            <td class="mb-0 fw-medium" colspan="2">Editing Discount: 10%
                                                            </td>
                                                            <td class="text-end" colspan="4"> - ${{
                                                                parseFloat(parseFloat((editingPackage.amount) * 10) /
                                                                    100).toFixed(2) }}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr v-if="addOnPackageItem">
                                                            <!-- {/* <td rowspan="1" class="fw-medium">
                                                                addOn Package
                                                            </td> */} -->
                                                            <td>
                                                                <p class="mb-0"
                                                                    v-if="(addOnPackageItem.title == '5 Journals' || addOnPackageItem.title == '15 Journals')">
                                                                    {{ addOnPackageItem.title }}
                                                                </p>
                                                                <p class="mb-0" v-else>{{ addOnPackageItem.title }} <sup
                                                                        class="text-theme">(${{ $route.query.amount
                                                                        }}/1000 words)</sup></p>

                                                            </td>
                                                            <td>{{ $route.query.wordCount }}</td>
                                                            <td class="text-end"
                                                                v-if="(addOnPackageItem.title == '5 Journals' || addOnPackageItem.title == '15 Journals')">
                                                                ${{ parseFloat($route.query.amount).toFixed(2) }}</td>
                                                            <td class="text-end" v-else>${{
                                                                parseFloat((Number($route.query.wordCount)) / 1000 *
                                                                    Number($route.query.amount)).toFixed(2) }}
                                                            </td>
                                                        </tr>

                                                        <!-- <tr
                                                            v-if="(submission?.title == '20 Journals' || fiction?.title == '20 Journals (3 Stories)' || submission?.title == 'Journals List Only') && (addOnPackageItem?.title == 'Line Editing' || addOnPackageItem?.title == 'Copy Editing') && addOnPackageItem">
                                                            <td class="mb-0 fw-medium" colspan="2">Editing Discount: 10%
                                                            </td>
                                                            <td class="text-end" colspan="4">- ${{
                                                                ((parseFloat(((Number($route.query.wordCount) /
                                                                    1000) *
                                                                    Number($route.query.amount))) * 10) / 100).toFixed(2) }}</td>
                                                        </tr> -->

                                                        <tr v-if="extraServiceItem">
                                                            <!-- {/* <td rowspan="1" class="fw-medium">
                                                                Extra Package
                                                            </td> */} -->
                                                            <td v-if="addOnPackageData">
                                                                <p class="mb-0"
                                                                    v-if="(extraServiceItem.title == '5 Journals' || extraServiceItem.title == '15 Journals')">
                                                                    {{ extraServiceItem.title }}
                                                                </p>
                                                                <p class="mb-0" v-else>{{ extraServiceItem.title }} <sup
                                                                        class="text-theme">(${{ extraServiceItem.amount
                                                                        }}/1000 words)</sup></p>

                                                            </td>
                                                            <td v-else>
                                                                <p class="mb-0">{{ extraServiceItem.title }} </p>
                                                            </td>
                                                            <td>{{ extraServiceItem.wordCount }}</td>
                                                            <td class="text-end"
                                                                v-if="(extraServiceItem.title == '5 Journals' || extraServiceItem.title == '15 Journals')">
                                                                ${{ parseFloat(extraServiceItem.amount).toFixed(2) }}
                                                            </td>
                                                            <td class="text-end" v-else-if="!addOnPackageData">${{
                                                                parseFloat(Number(extraServiceItem.amount)).toFixed(2)
                                                            }}
                                                            </td>
                                                            <td class="text-end" v-else>${{
                                                                parseFloat((Number(extraServiceItem.wordCount)) / 1000 *
                                                                    Number(extraServiceItem.amount)).toFixed(2) }}</td>

                                                        </tr>
                                                        <!-- <tr v-if="(addOnSubmission?.title == '20 Journals' || addOnFlash?.title == '20 Journals (3 Stories)' || addOnSubmission?.title == 'Journals List Only') && (extraServiceItem?.title == 'Line Editing' || extraServiceItem?.title == 'Copy Editing') && extraServiceItem">
                                                            <td class="mb-0 fw-medium" colspan="2">Editing Discount: 10%
                                                            </td>
                                                            <td class="text-end" colspan="4">- ${{
                                                                ((parseFloat(((Number($extraServiceItem.wordCount) /
                                                                    1000) *
                                                                    Number($extraServiceItem.amount))) * 10) / 100).toFixed(2) }}</td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-md-7 mt-4 order-md-1 order-2">
                                                    <p>
                                                        All services: 100% money-back guarantee (within 30
                                                        days).
                                                    </p>
                                                    <p>
                                                        If you have any questions, please contact us at
                                                        <a class="text-theme"
                                                            href="mailto:info@submititnow.com">info@submititnow.com.</a>
                                                    </p>
                                                    <p>
                                                        View our
                                                        <a class="text-theme"
                                                            href="https://www.submititnow.com/terms-of-service">Terms of
                                                            Service.</a>
                                                    </p>
                                                </div>
                                                <div class="col-md-5 bg-new pb-4 order-md-2 order-1">
                                                    <div class="table-responsive">


                                                        <table class="table mb-0 remove-bg">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="mb-0 fw-medium">Subtotal </td>
                                                                    <td class="text-end">${{
                                                                        parseFloat(totalAmount).toFixed(2) }}</td>
                                                                </tr>
                                                                <!-- <tr
                                      v-if="(submissionPackage?.title == '20 Journals' || fictionPackage?.title == '20 Journals (3 Stories)') && editingPackage">
                                      <td class="mb-0 fw-medium">Discount <sup class="text-theme">(Editing &
                                          Consulting)</sup> </td>
                                      <td class="text-end">10%</td>
                                    </tr> -->



                                                                <!-- <tr v-if="coupon">
                                            <td>
                                              <p class="mb-0 fw-medium">Coupon</p>
                                            </td>
                                            <td class="text-end" v-if="coupon.discount_type == 'fixed'">${{
              coupon.discount_amount }}</td>
                                            <td class="text-end" v-else>{{ coupon.discount_amount }}%</td>
                                          </tr> -->

                                                                <tr>
                                                                    <td class="mb-0 fw-medium">Total</td>
                                                                    <td class="text-end">
                                                                        <strong>${{ parseFloat(totalAmount).toFixed(2)
                                                                            }}</strong>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <!-- <div class="w-100  pt-3 px-2">
                                      <label class="form-label">Apply Coupon</label>
                                      <div class="input-group">
                                        <input type="text" class="form-control" v-model="coupon_code" id="inputGroupFile04"
                                          aria-describedby="inputGroupFileAddon04" aria-label="Upload" />
                                        <button class="btn btn-theme" @click="applyCoupon" pe="button"
                                          id="inputGroupFileAddon04">
                                          APPLY
                                        </button>
                                      </div>
                                    </div> -->
                                                    <!-- <div class="pt-1 ps-2">
                                      <span class="badge badge-pill badge-soft-success font-size-11"
                                        v-if="isCouponSuccess">{{ couponSuccess
                                        }}</span>
                                      <span class="badge badge-pill badge-soft-success font-size-11 badge-soft-danger"
                                        v-if="isCouponError">{{ couponError }}</span>
                                    </div> -->
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="card">
                                        <div class="card-body sticky-payment">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h4 class="card-title">Payment information</h4>
                                                    <p class="card-title-desc">Please fill out all information below</p>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-check form-check-inline font-size-16 ps-0">
                                                        <h4 class="font-size-13"><i
                                                                class="fab fa-cc-mastercard me-1 font-size-20 align-top"></i>
                                                            Credit / Debit
                                                            Card</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="py-3 ">
                                                <form>
                                                    <div class="row">
                                                        <div class="col-lg-6 mb-3">
                                                            <div class="form-group mb-0">

                                                                <label for="cardnumberInput">Card Number</label>
                                                                <div type="text" class="form-control validateInput"
                                                                    id="example2-card-number"
                                                                    placeholder="0000 0000 0000 0000"
                                                                    :class="{ 'is-invalid': this.addPaymentStatusError.code == 'incomplete_number' || this.addPaymentStatusError.code == 'invalid_number' }">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 mb-3">
                                                            <div class="form-group mt-0 mb-0">
                                                                <label for="cardnameInput">Name on Card</label>
                                                                <input type="text" v-model="cardHolderName"
                                                                    class="form-control validateInput"
                                                                    id="cardnameInput" placeholder="Name on Card">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger mb-3 mt-n3 d-block"
                                                        v-if="this.addPaymentStatusError.code == 'incomplete_number' || this.addPaymentStatusError.code == 'invalid_number' || this.addPaymentStatusError.type == 'card_error'">{{
                                                            this.addPaymentStatusError.message }}</span>
                                                    <div class="row">
                                                        <!-- <div class="col-lg-6">
                                        <div class="form-group mt-4 mb-0">
                                            <label for="cardnameInput">Name on card</label>
                                            <input type="text" v-model="cardHolderName"
                                                class="form-control validateInput" id="cardnameInput"
                                                placeholder="Name on Card">
                                        </div>
                                    </div> -->
                                                        <div class="col-lg-6 mb-3">
                                                            <div class="form-group mb-0">
                                                                <label for="expirydateInput">Expiration Date</label>
                                                                <div type="text" class="form-control validateInput"
                                                                    id="example2-card-expiry" placeholder="MM/YY"
                                                                    :class="{ 'is-invalid': this.addPaymentStatusError.code == 'incomplete_expiry' || this.addPaymentStatusError.code == 'invalid_expiry_year_past' }">
                                                                </div>
                                                            </div>
                                                            <span class="text-danger mb-3 mt-n3 d-block"
                                                                v-if="this.addPaymentStatusError.code == 'incomplete_expiry' || this.addPaymentStatusError.code == 'invalid_expiry_year_past'">{{
                                                                    this.addPaymentStatusError.message }}</span>
                                                        </div>
                                                        <div class="col-lg-6 mb-3">
                                                            <div class="form-group mb-0">
                                                                <label for="cvvcodeInput">CVV Code</label>
                                                                <div type="text" id="example2-card-cvc"
                                                                    class="form-control validateInput"
                                                                    placeholder="Enter CVV Code"
                                                                    :class="{ 'is-invalid': this.addPaymentStatusError.code == 'incomplete_cvc' }">
                                                                </div>
                                                            </div>
                                                            <span class="text-danger mb-3 mt-n3 d-block"
                                                                v-if="this.addPaymentStatusError.code == 'incomplete_cvc'">{{
                                                                    this.addPaymentStatusError.message }}</span>
                                                        </div>

                                                    </div>
                                                    <div
                                                        class="d-flex flex-wrap gap-2 justify-content-between mt-4 pt-3 text-end">
                                                        <button type="button" @click="$router.go(-1)"
                                                            class="btn btn-theme">
                                                            <i class="mdi mdi-arrow-left"></i>
                                                            Back</button>
                                                        <button id="doPayment" type="button" @click="doAddOnPayment"
                                                            class="btn btn-theme"
                                                            v-if="$route.name == 'add-on-payment'">
                                                            <span class="spinner-border spinner-border-sm "
                                                                v-if="spinner" role="status" aria-hidden="true"></span>
                                                            Place Your Order ${{ parseFloat(totalAmount).toFixed(2)
                                                            }}</button>
                                                        <button id="doPayment" type="button" @click="doPayment"
                                                            class="btn btn-theme"
                                                            v-else-if="$route.name == 'payment' && isServicePayment == false">
                                                            <span class="spinner-border spinner-border-sm "
                                                                v-if="spinner" role="status" aria-hidden="true"></span>
                                                            Place Your Order ${{ parseFloat(totalAmount).toFixed(2)
                                                            }}</button>
                                                        <button id="doPayment" type="button" @click="doPaymentLink"
                                                            class="btn btn-theme"
                                                            v-else-if="$route.name == 'payment' && isServicePayment == true">
                                                            <span class="spinner-border spinner-border-sm "
                                                                v-if="spinner" role="status" aria-hidden="true"></span>
                                                            Place Your Order ${{ parseFloat(totalAmount).toFixed(2)
                                                            }}</button>
                                                    </div>

                                                </form>
                                                <!--  -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    </div>
</template>
<script>
// import PageHeader from "@/components/admin/header/page-header";
// import HorizontalTopbar from "@/components/admin/header/horizontal-topbar";
import Footer from "@/components/admin/footer/footer";
import axios from "axios";
import CryptoJS from 'crypto-js';
// import check from '@/assets/images/submitit/check-3.gif'

export default ({
    components: {
        // PageHeader,
        // HorizontalTopbar,
        Footer
    },
    data() {
        return {
            url: process.env.VUE_APP_URL,
            stripeAPIToken: 'pk_live_51PQNfhInA3lz1UUr2wdgbmMB64qAhKCr0flvYtSwtlmA6fw6t8LHKo52aDBQydRGz4gJWFbBh9es3wgGIwJu7ChY00yHLObPc4',
            stripe: '',
            elements: null,
            card: '',
            clientSecret: "",
            paymentIntentError: '',
            addPaymentStatusError: '',
            cardElement: null,
            cardHolderName: null,
            zipCode: null,
            spinner: false,
            selectedPackages: [],
            addOnPackageItem: '',
            packages: [],
            email: null,
            totalAmount: 0,
            discount: 0,
            extraService: false,
            isServicePayment: false,
            extraServiceItem: '',
            paymentLinkPackages: [],

            extraServiceItemData: [],
            addOnSubmission: [],
            addOnFlash: [],
            addOnPackageData: '',
            successPayment: false,
            submissionPackage: '',
            fictionPackage: '',
            editingPackage: '',
            coupon: '',
            customer_id: '',
            submission: '',
            fiction: '',
            storyWordCount: null,
            story_id: '',
            storyDetails: '',
        };
    },
    watch: {
        $route(to, from) {
            if ((to.params.id !== from.params.id) && (to.name == 'add-on-payment'|| to.name == 'payment')) {
                this.resetFields();
                this.getPackages();
            }
        }
    },
    activated() {
        this.getPackages();
    },
    beforeMount() {
        this.getPackages();
    },
    methods: {
        includeStripe(URL, callback) {
            let documentTag = document, tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = '//' + URL;
            if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        },
        configureStripe() {
            this.stripe = window.Stripe(this.stripeAPIToken);
            const appearance = {
                theme: 'stripe',
            }
            let clientSecret = this.clientSecret;
            this.elements = this.stripe.elements({ clientSecret, appearance });
            var inputs = document.querySelectorAll('.validateInput');
            Array.prototype.forEach.call(inputs, function (input) {
                input.addEventListener('focus', function () {
                    input.classList.add('focused');
                });
                input.addEventListener('blur', function () {
                    input.classList.remove('focused');
                });
                input.addEventListener('keyup', function () {
                    if (input.value.length === 0) {
                        input.classList.add('empty');
                    } else {
                        input.classList.remove('empty');
                    }
                });
            });

            var elementStyles = {
                base: {
                    color: '#32325D',
                    fontWeight: 500,
                    fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',

                    '::placeholder': {
                        color: '#CFD7DF',
                    },
                    ':-webkit-autofill': {
                        color: '#e39f48',
                    },
                },
                invalid: {
                    color: '#E25950',

                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            };

            var elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            };

            this.cardElement = this.elements.create('cardNumber', {
                style: elementStyles,
                classes: elementClasses,
            });
            this.cardElement.mount('#example2-card-number');

            this.cardElement = this.elements.create('cardExpiry', {
                style: elementStyles,
                classes: elementClasses,
            });
            this.cardElement.mount('#example2-card-expiry');

            this.cardElement = this.elements.create('cardCvc', {
                style: elementStyles,
                classes: elementClasses,
            });
            this.cardElement.mount('#example2-card-cvc');

            this.cardElement.addEventListener("change", function (event) {
                // if (event.empty) {
                document.getElementById("doPayment").disabled = event.empty;

                // }
            });
        },
        // loadIntent() {
        //     axios.get(this.url + 'api/setup-intent').then(res => {
        //         this.clientSecret = res.data.client_secret;
        //         this.includeStripe('js.stripe.com/v3/', function () {
        //             this.configureStripe();
        //         }.bind(this));
        //     })
        // },
        getPackages() {
            axios.get(this.url + 'api/getPackages').then(res => {
                this.packages = res.data;
                this.selectedPackages = res.data.filter(item => item.stripe_package_id == this.$route.query.addOnPackage);
                this.addOnPackageItem = res.data.find(item => item.stripe_package_id == this.$route.query.addOnPackage);

                this.submission = res.data.find(item => item.id == this.$route.query.submission);
                this.fiction = res.data.find(item => item.id == this.$route.query.flash);

                // if ((this.submission?.title == '20 Journals' || this.fiction?.title == '20 Journals (3 Stories)' || this.submission?.title == 'Journals List Only') && (this.addOnPackageItem?.title == 'Line Editing' || this.addOnPackageItem?.title == 'Copy Editing') && this.addOnPackageItem) {
                //     this.discount = ((parseFloat(((Number(this.$route.query.wordCount) / 1000) * (this.$route.query.amount))) * 10) / 100);
                // }

                if (this.$route.query.addOnPackage) {
                    if ((this.addOnPackageItem?.title == '5 Journals' || this.addOnPackageItem?.title == '15 Journals')) {
                        // this.totalAmount = (parseFloat(this.$route.query.amount) - parseFloat(this.discount));
                        this.totalAmount = (parseFloat(this.$route.query.amount));
                    } else {
                        // this.totalAmount = (parseFloat(((Number(this.$route.query.wordCount)) / 1000 * Number(this.$route.query.amount)).toFixed(2)) - parseFloat(this.discount));
                        this.totalAmount = (parseFloat(((Number(this.$route.query.wordCount)) / 1000 * Number(this.$route.query.amount)).toFixed(2)));
                    }
                }
                if (this.$route.name == 'add-on-payment') {
                    this.loadAddOnIntent();
                } else {
                    this.getPackagesData()
                }
            })
        },
        getPackagesData() {
            axios.post(this.url + 'api/getSelectedPackages', { link: this.$route.params.id }).then(res => {
                if (res.data.length === 0) {
                    this.loadPaymentIntent()
                }
                else if (res.data.status == 'Success') {
                    alert('This link has already been used and your transaction has been successfully processed.')
                    this.$router.push('/')
                }
                else {
                    this.storyWordCount = res.data.story.wordCount;
                    this.isServicePayment = true;
                    this.selectedPackages = res.data.packages.split(',');
                    this.email = res.data.email
                    this.story_id = res.data.story.id;
                    this.storyDetails = res.data.story;
                    this.packagesShow();
                    this.loadPaymentIntentLink()
                }
            })
        },
        packagesShow() {
            let packIds = this.selectedPackages.map(item => item); // Extract package IDs
            this.paymentLinkPackages = this.packages.filter(item => packIds.includes(item.stripe_package_id));
            this.submissionPackage = this.paymentLinkPackages.find(pack => pack.type == 'submission');
            this.fictionPackage = this.paymentLinkPackages.find(pack => pack.type == 'flash');
            this.editingPackage = this.paymentLinkPackages.find(pack => pack.type == 'editing');
            this.totalAmount = this.calculateTotal();
        },

        calculateTotal() {
            let total = 0;
            if (this.submissionPackage) {
                total += parseFloat(this.submissionPackage.amount);
            }

            if (this.fictionPackage) {
                total += parseFloat(this.fictionPackage.amount);
            }

            if (this.editingPackage && this.editingPackage?.title == 'Consulting: 1 hour') {
                total += parseFloat(this.editingPackage.amount);
            }
            if ((this.submissionPackage?.title == '20 Journals' || this.fictionPackage?.title == '20 Journals (3 Stories)' || this.submissionPackage?.title == 'Journals List Only') && (this.editingPackage && this.editingPackage?.title != 'Consulting: 1 hour')) {
                total -= (parseFloat(((Number(this.storyWordCount) / 1000) * this.editingPackage.amount)) * 10) / 100;
            } else if ((this.submissionPackage?.title == '20 Journals' || this.fictionPackage?.title == '20 Journals (3 Stories)' || this.submissionPackage?.title == 'Journals List Only') && (this.editingPackage && this.editingPackage?.title == 'Consulting: 1 hour')) {
                total -= (parseFloat(this.editingPackage.amount) * 10) / 100;
            }

            if (this.coupon) {
                if (this.coupon.discount_type === 'fixed') {
                    total -= parseFloat(this.coupon.discount_amount);
                } else {
                    total -= (total * parseFloat(this.coupon.discount_amount)) / 100;
                }
            }

            if (this.storyWordCount > 3500 && !this.editingPackage) {
                total += ((Number(this.storyWordCount) - 3500) / 1000) * 35;
            } else if (this.editingPackage?.title == 'Line Editing' && this.submissionPackage?.title == 'Journals List Only') {
                total += (Number(this.storyWordCount) / 1000) * 85;
            } else if (this.editingPackage?.title == 'Line Editing' && this.submissionPackage?.title != 'Journals List Only') {
                total += (Number(this.storyWordCount) / 1000) * 85;
            } else if (this.editingPackage?.title == 'Copy Editing' && this.submissionPackage?.title != 'Journals List Only') {
                total += (Number(this.storyWordCount) / 1000) * 60;
            } else if (this.editingPackage?.title == 'Copy Editing' && this.submissionPackage?.title == 'Journals List Only') {
                total += (Number(this.storyWordCount) / 1000) * 60;
            } else if (this.editingPackage?.title == 'Read Only') {
                total += (Number(this.storyWordCount) / 1000) * 35;
            }

            return total.toFixed(2);
        },

        loadPaymentIntentLink() {
            axios.post(this.url + "api/payment-intent", {
                email: this.email,
                submissionPackage: this.submissionPackage ? this.submissionPackage.id : '',
                fictionPackage: this.fictionPackage ? this.fictionPackage.id : '',
                editingPackage: this.editingPackage ? this.editingPackage.id : '',
                coupon: this.coupon ? this.coupon.coupon_code : '',
                story_id: this.story_id,
                totalAmount: this.calculateTotal()
            })
                .then((res) => {
                    this.customer_id = res.data.customer_id;
                    this.clientSecret = res.data.paymentIntent.client_secret;
                    this.includeStripe(
                        "js.stripe.com/v3/",
                        function () {
                            this.configureStripe();
                        }.bind(this)
                    );
                });
        },
        loadPaymentIntent() {
            axios.post(this.url + 'api/extraService', { serviceToken: this.$route.params.id, id: atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))) }).then(res => {
                if (res.data.success == 1) {
                    this.extraServiceItemData = res.data
                    this.extraServiceItem = res.data.extraService;
                    this.totalAmount = parseFloat(res.data.totalAmount);
                    this.addOnSubmission = res.data.submission;
                    this.addOnFlash = res.data.flash;
                    this.addOnPackageData = res.data.addOn;
                    this.clientSecret = res.data.paymentIntent.client_secret
                    this.storyDetails = res.data.story;
                    this.includeStripe('js.stripe.com/v3/', function () {
                        this.configureStripe();
                    }.bind(this));
                } else if (res.data.success == 2) {
                    alert('Payment link does not match our records.');
                } else if (res.data.success == 3) {
                    alert('This link has already been used and your transaction has been successfully processed.');
                    this.$router.push('/')
                } else {
                    alert('An error occurred. We will contact you to confirm payment. Sorry for the inconvenience.');
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        loadAddOnIntent() {
            axios.post(this.url + 'api/addOnPaymentIntent', {
                id: atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))),
                total: this.totalAmount,
                story_id: this.$route.query.story_id,
                discount: this.discount,
                addOnPackage: this.selectedPackages.map(item => item.id)[0]
            }).then(res => {
                this.clientSecret = res.data.paymentIntent.client_secret;
                this.storyDetails = res.data.story;
                this.includeStripe('js.stripe.com/v3/', function () {
                    this.configureStripe();
                }.bind(this));
            })
        },
        // loadPaymentIntentData() {
        //     axios.post(this.url + 'api/loadPaymentIntentData', {
        //         email: this.email,
        //         paymentLink: this.$route.params.id,
        //         plans: this.selectedPackages,
        //     }).then(res => {
        //         this.clientSecret = res.data.paymentIntent.client_secret
        //         this.includeStripe('js.stripe.com/v3/', function () {
        //             this.configureStripe();
        //         }.bind(this));
        //     })
        // },
        // submitPaymentMethod() {
        //     if (!this.extraService) {
        //         this.stripe.confirmCardSetup(
        //             this.clientSecret, {
        //             payment_method: {
        //                 card: this.cardElement,
        //                 billing_details: {
        //                     name: this.cardHolderName
        //                 }
        //             }
        //         }
        //         ).then(function (result) {
        //             if (result.error) {
        //                 this.addPaymentStatusError = result.error;
        //             } else {
        //                 this.createSubscription(result.setupIntent.payment_method);
        //                 this.cardElement.clear();
        //                 this.name = '';
        //             }
        //         }.bind(this));
        //     }
        //     else {
        //         this.doPayment()
        //     }

        // },

        // createSubscription(paymentId) {
        //     this.spinner = true;
        //     axios.post(this.url + 'api/createSubscription', { 'email': this.email, 'paymentLink': this.$route.params.id, 'plans': this.selectedPackages, 'paymentId': paymentId }).then(() => {
        //         this.successPayment = true;
        //         setTimeout(() => {
        //             this.$router.push('/')
        //         }, 2500)
        //     }).catch((error) => {
        //         console.log(error)
        //         this.spinner = false;
        //     })
        // },

        async doPaymentLink() {
            this.spinner = true;
            const { paymentIntent, error } = await this.stripe.confirmCardPayment(this.clientSecret, {
                payment_method: {
                    card: this.cardElement,
                }
            });

            if (error) {
                this.addPaymentStatusError = error;
                this.spinner = false;
                console.error(error);
                return false;
            }
            else {
                const response = await axios.post(this.url + 'api/confirmPaymentIntent', {
                    payment_intent_id: paymentIntent.id,
                    paymentLink: this.$route.params.id,
                    email: this.email
                });
                if (response.data.success) {
                    this.spinner = false;
                    this.successPayment = true;
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 2500)
                } else {
                    this.spinner = false;
                    console.log(response.data);
                }
            }
        },
        async doPayment() {
            this.spinner = true;
            const { paymentIntent, error } = await this.stripe.confirmCardPayment(this.clientSecret, {
                payment_method: {
                    card: this.cardElement,
                }
            });

            if (error) {
                this.addPaymentStatusError = error
                this.spinner = false;
                console.error(error.message);
            }
            else {
                const response = await axios.post(this.url + 'api/confirm-payment-intent', {
                    payment_intent_id: paymentIntent.id,
                    token: this.$route.params.id,
                    addOnId: this.addOnPackageData?.id
                });

                if (response.data.success) {
                    this.spinner = false;
                    this.successPayment = true;
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 2500)
                } else {
                    console.error(response.data.error);
                }
            }
        },
        async doAddOnPayment() {
            this.spinner = true;
            const { paymentIntent, error } = await this.stripe.confirmCardPayment(this.clientSecret, {
                payment_method: {
                    card: this.cardElement,
                }
            });

            if (error) {
                this.addPaymentStatusError = error
                this.spinner = false;
                console.error(error);
            }
            else {
                const response = await axios.post(this.url + 'api/addOnConfirmPaymentIntent', {
                    payment_intent_id: paymentIntent.id,
                    token: this.$route.params.id,
                    id: atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))),
                    story_id: this.$route.query.story_id,
                    wordCount: this.$route.query.wordCount,
                    addOnPackage: this.$route.query.addOnPackage,
                });

                if (response.data.success) {
                    this.spinner = false;
                    this.successPayment = true;
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 2500)
                } else {
                    console.error(response.data.error);
                }
            }
        },
        resetFields() {
            this.successPayment = false;
            this.selectedPackages = [];
            this.addOnPackageItem = '';
            this.packages = [];
            this.extraService = false;
            this.isServicePayment = false;
            this.extraServiceItem = '';
            this.paymentLinkPackages = [];
            this.extraServiceItemData = [];
            this.addOnSubmission = [];
            this.addOnFlash = [];
            this.addOnPackageData = '';
            this.successPayment = false;
            this.submissionPackage = '';
            this.fictionPackage = '';
            this.editingPackage = '';
            this.coupon = '';
            this.submission = '';
            this.fiction = '';
            this.totalAmount = 0;
            this.discount = 0;

        },
        decode(id) {
            return decodeURIComponent(CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(CryptoJS.enc.Utf8));
        },
    }
})
</script>
<style>
.paymentSuccess h2 {
    font-size: 32px;
    color: #1a4718;
    margin-top: -26px;
}

.example.example2 {
    background-color: #fff;
}

.example.example2 .input.focused+label {
    color: #24b47e;
}

.example.example2 .input.invalid+label {
    color: #e21313;
}

.example.example2 .input.focused+label+.baseline {
    background-color: #24b47e;
}

.example.example2 .input.focused.invalid+label+.baseline {
    background-color: #d32115;
}

.card.payment_form {
    max-width: 50%;
    margin: auto;
}

.example.example2 input,


.example.example2 input:-webkit-autofill {
    -webkit-text-fill-color: #b10606;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
}

.example.example2 .StripeElement--webkit-autofill {
    background: transparent !important;
}

.example.example2 .error svg {
    margin-top: 0 !important;
}

.example.example2 .error svg .base {
    fill: #ee1f10;
}

.example.example2 .error svg .glyph {
    fill: #fff;
}

.example.example2 .error .message {
    color: #dd2619;
}

.sticky-payment {
    background: rgb(235 235 235) !important;

}

.remove-bg th,
.remove-bg td {
    background: transparent;
    padding: 15px 10px;
    white-space: nowrap;
}

.bg-new {
    background: #f8f9fa;
}
</style>